<template>
	<div class="firminfo">
		<div class="box-top"></div>
		<div class="top-hurdle">
			<img src="../assets/mask/logo.png" 
           style="width: 95px; height: 30px;margin-top: 10px;margin-left: 20px;" />
			<div class="left-text c-p" @click="handleCriterion">
				合规体检
			</div>
			<div class="right-text c-p">
				公司介绍
			</div>
			<div class="exit">
				<el-button type="text"
          @click="handleExit"
        >个人中心<i class="el-icon-arrow-right el-icon--right"></i>
        </el-button>
			</div>
		</div>
		<div class="box-center">
			<div class="box-logo">
        <el-image 
          fit="cover"
          :src="logo || require('../assets/mask/login/logo.png')" 
          style="width: 110px; height: 110px;border-radius: 50%;"></el-image>
				<div class="logo-text">
          <span class="title">{{MemberDetail.companyName || '暂无数据'}}</span>	
          <img src="../assets/mask/bj.png" 
            style="width: 18px; height: 18px;cursor: pointer"
            @click="handleEdit"/>
				</div>
			</div>
			<div class="content">
				<div style="text-align: center;color: #1577CB;">
					企业介绍
					<div class="box-title"></div>
				</div>
				<div class="flrmin-text" v-if="MemberDetail.companyProfile">
					{{MemberDetail.companyProfile}}
				</div>
        <el-empty v-else description="暂无数据"></el-empty>
				<div style="padding-top: 35px;">
					<el-button type="warning" @click="handleCriterion">企业合规体检</el-button>
				</div>
			</div>
			<div class="floor">
				Copyright ©2022-2025  Mask版权所有
			</div>
		</div>
		<el-dialog
      title="编辑信息"
      :visible.sync="dialogVisible"
      width="480px"
      :before-close="handleClose">
      <el-form ref="formEdit" :model="formEdit" class="formEdit" label-width="0">
        <el-form-item v-model="formEdit.companyLogo" prop="companyLogo">
					<div class="edit-label">企业logo</div>
					<el-upload
						ref="ImagesFrom"
						action="/"
						:auto-upload="true"
						:http-request="indexUploadImg"
						:limit="1"
						:accept="'image/*'"
						list-type="picture-card"
						:file-list="indexFileImg"
						:on-preview="handlePreview"
						:on-remove="handleRemove"
						:on-exceed="handleExceed"
						:before-upload="uploadBefore">
						<i class="el-icon-plus"></i>
						<div slot="tip" class="el-upload__tip" style="color:#8CB4FF">建议图片格式：jpg/bmp/png，大小在300k内。</div>
					</el-upload>
        </el-form-item>
        <el-form-item>
          <div class="edit-label">企业名称</div>
          <el-input v-model="formEdit.companyName" placeholder="请输入企业名称"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="edit-label">企业介绍</div>
          <el-input 
						v-model="formEdit.companyProfile" 
						placeholder="请输入企业介绍"
						type="textarea"
						:autosize="{ minRows: 2, maxRows: 6}"
					></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="retrieve-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSave('formEdit')">保存</el-button>
      </div>
		</el-dialog>
		<el-dialog :visible.sync="dialogView" :modal-append-to-body="false">
			<img
				width="100%"
				:src="dialogImageUrl"
				alt=""
			>
		</el-dialog>
	</div>
</template>

<script>
  import { mapGetters } from 'vuex'
	import { getMemberDetail, uploadFile, getMemberSubmit } from '../api'; 
	export default {
    name: 'firminfo',
    data() {
			return {
        MemberDetail: {},
        userId: '',
        logo: '',
				number: '',
				password: '',
				dialogVisible: false,
				formEdit: {
          companyLogo: '',
          companyName: '',
          companyProfile: '',
        },
				/** 图片上传 */
				dialogView: false,
				dialogImageUrl: '',
				indexFileImg: [],
        rules: {
          companyLogo:[{required: true, message: "请上传图片", trigger: "blur" }],
          companyName: [{required: true, message: "请输入企业名称", trigger: "blur" }],
          companyProfile: [{required: true, message: "请输入企业介绍", trigger: "blur" }],
        }
			}
		},
    created() {
      this.userId = this.userInfo.user_id;
      this.initData();
    },
    computed: {
      ...mapGetters(["userInfo"])
    },
		methods: {
      initData() {
         getMemberDetail({ id: this.userId }).then(({ data }) => {
          this.MemberDetail = data;
          if (data.companyLogo) {
            this.logo = data.companyLogo;
          }
        });
      },
			handleEdit() {
        this.formEdit.companyName = this.MemberDetail.companyName;
        this.formEdit.companyProfile = this.MemberDetail.companyProfile;
        if (this.MemberDetail.companyLogo) {
          let logo = new Object();
          logo.url = this.MemberDetail.companyLogo
          this.indexFileImg.push(logo);
        }
				this.dialogVisible = true;
			},
			handleSave(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            const loading = this.$loading({
              lock: true,
              text: "保存中...",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            const { companyLogo, companyName, companyProfile } = this.formEdit;
            getMemberSubmit({
              id: this.userId,
              companyLogo,
              companyName,
              companyProfile,
            }).then(()=> {
              this.$message({
                  type: "success",
                  message: "操作成功!"
              });
              loading.close();
              this.initData();
              this.dialogVisible = false;
              this.indexFileImg = [];
            });
          } else {
            return ;
          }
        });
			},
			handleClose() {
				this.dialogVisible = false;
        this.indexFileImg = [];
        this.formEdit={
          companyLogo: '',
          companyName: '',
          companyProfile: '',
        };
			},
			/*图片上传*/
			uploadBefore(file) {
				if(file.size/1024 > 3036) {
					this.$message.warning("上传文件过大，请注意文件大小!")
					return false;
				}else{
					return true;
				}
			},
			indexUploadImg(e) {
				const loading = this.$loading({
					lock: true,
					text: "上传中...",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				let formData = new FormData();
				formData.append("file", e.file);
				uploadFile(formData).then(res =>{
					e.onSuccess(res);
					loading.close();
					this.$message.success("上传成功！");
					this.formEdit.companyLogo = res.data.link;
				});
			},
			handlePreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogView = true;
			},
			handleRemove() {
				this.formEdit.companyLogo = '';
			},
			handleExceed() {
				this.$message.warning(`只能选择 1 张图片`);
			},
      handleCriterion() {
				this.$router.push({path:'/criterion'});
			},
      handleExit() {
				this.$router.push({path: '/personal'});
			},
		}
	}
</script>

<style lang="less">
	.firminfo{
    .c-p{
      cursor: pointer;
    }
		.box-top{
			width: 100%;
			height: 440px;
			background: url('../assets/mask/bg.png') no-repeat;
			background-size: 100% 100%;
			position: absolute;
			z-index: -1;
		}
		.top-hurdle{
			width: 100%;
			height: 50px;
			background: #444A59;
			display: flex;
			font-size: 15px;
			color: #cccacc;
			line-height: 50px;
			
			.left-text{
				margin: 0 20px;
			}
			.right-text{
				color: #fff;
				border-bottom: 2px solid #DAB87A;
			}
			.exit{
				.el-button--text{
					color: #cccacc;
				}
				position: absolute;
				right: 5px;
			}
			
		}
		.box-center{
			width: 430px;
			margin: 0 auto;
			position: relative;
			
			.box-logo{
				padding-top: 60px;
				padding-bottom: 35px;
				text-align: center;
			}
			.logo-text{
				padding-top: 19px;
				color: #fff;
				img {
					position: absolute;
					right: 0;
				}
			}
		}
		.content{
			width: 332px;
			background-color: #fff;
			border-radius: 5px;
			padding: 15px 49px;
			
			.box-title{
				height: 3px;
				width: 30px;
				background: #DAB87A;
				margin: 0 auto;
				margin-top: 8px;
				margin-bottom: 30px;
			}
			.flrmin-text::-webkit-scrollbar {
        width: 10px;
        background-color: #fff;
      }
      // /*鼠标移动上去再显示滚动条*/
      .flrmin-text ::-webkit-scrollbar-track-piece {
        background-color: #fff;
        border-radius: 6px;
      }
      .flrmin-text::-webkit-scrollbar-thumb:hover {
        background-color: #cdd7e0;
      }
      .flrmin-text::-webkit-scrollbar-thumb:vertical {
        background-color: #cdd7e0;
        border-radius: 6px;
        outline: 2px solid #fff;
        outline-offset: -2px;
        border: 2px solid #fff;
      }
			.flrmin-text{
				font-size: 14px;
        line-height: 20px;
        max-height: 250px;
        overflow-y: auto;
        white-space: pre-wrap;
			}
			
			.el-button--warning:focus, 
			.el-button--warning:hover,
			.el-button--warning {
				background-color: #DAB87A;
				border-color: #DAB87A;
				width: 100%;
				margin-bottom: 25px;
				font-size: 18px;
			}
		}
		.floor{
			font-size: 14px;
			color: #C8C8C8;
			padding-left: 90px;
			position: absolute;
			bottom: 10px;
			z-index: -1;
		}
		
		.el-dialog__footer{
			padding: 0;
			text-align: left;
		}
		.el-dialog__body{
			padding: 0 20px 10px 20px;
		}
		.retrieve-footer{
			.el-button{
				width: 240px;
				border-radius: 0;
			}
			.el-button+.el-button{
				margin-left: 0;
			}
			.el-button--default{
				background: #DAB87A;
				border: 1px solid #DAB87A;
				color: #fff;
			}
			.el-button--primary{
				color: #FFF;
				background-color: #1577CB;
				border-color: #1577CB;
			}
		}
		.formEdit{
			.el-upload--picture-card{
				width: 100px;
				height: 100px;
				line-height: 100px;
			}
      .el-upload-list__item{
				width: 100px;
				height: 100px;
      }
		}

	}
</style>

